import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import Theme1 from "./addPages/theme1";
import Theme2 from "./addPages/theme2";
import Theme3 from "./addPages/theme3";
import Theme4 from "./addPages/theme4";
import Theme5 from "./addPages/theme5";
import Theme6 from "./addPages/theme6";
import Theme7 from "./addPages/theme7";
import Theme8 from "./addPages/theme8";
import Theme9 from "./addPages/theme9";
import Theme10 from "./addPages/theme10";
import Theme11 from "./addPages/Theme11";
import Theme12 from "./addPages/Theme12";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";
import swal from "sweetalert";
import { SITEBUILDER_ACCESS_POINT } from "../../../config";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";

const options = [
  { label: "Theme-1 (To Add Content for Page)", value: "1" },
  { label: "Theme-2 (Simple HTML editor)", value: "2" },
  { label: "Theme-3 (To Add images,Text and Videos)", value: "3" },
  { label: "Theme-4 (To Add Content Section wise)", value: "4" },
  { label: "Theme-5 (To Display Box type Content in Page)", value: "5" },
  { label: "Theme-6 (To Add Links in Pages)", value: "6" },
  { label: "Theme-7 (Advanced HTML Editor)", value: "7" },
  { label: "Theme-8 (To Add Form)", value: "8" },
  { label: "Theme-9 (To Add Images,Description in Page)", value: "9" },
  { label: "Theme-10", value: "10" },
  { label: "Theme-11 (To add a content repeatedly)", value: "11" },
  { label: "Theme-12 (To add a product repeatedly)", value: "12" }
];
class AddPagebc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerHintShow: false,
      alertVisible: false,
      authorlist: [],
      checked: 0,
      themeDisable: true,
      pagenameDisable: true,
      customerId: localStorage.getItem("userId"),
      errorPageName: null,
      data: [],
      themeId: 0,
      authorname: "",
      authoremail: "",
      authorabout: "",
      authorlogo: "",
      file1: "",
      errorauthorname: "",
      errorauthoremail: "",
      errorauthorabout: "",
      alertVisible1: false,
      btnDisable: false,
      formAlert: false,
      formBtn: false,
      themeSelected: "",
      screen: window.screen.height,
      danger: "btn btn-danger",
      success: "btn btn-success",
      home: "Home",
      set: "SetusHome",
      autherSelected: {},
      userlog2: '',
      productThemePresent: false
    };
  }
  column = [
    {
      Header: "Page id",
      accessor: "id"
    },
    {
      Header: "Page",
      accessor: "name",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Theme",
      accessor: "edit",
      Cell: (d) => this.showTheme(d),
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: (d) => this.showVisibility(d),
    },
    {
      Header: "Require Login",
      accessor: "requireLogin",
    },
    {
      Header: "Change Login Status",
      accessor: "edit",
      Cell: (d) => this.changeLoginStatus(d),
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.Editpages(d),
    },
    {
      Header: "Preview",
      accessor: "edit",
      Cell: (d) => this.PreviewPage(d),
    },
    {
      Header: "HomePage",
      accessor: "edit",
      Cell: (d) => this.homepage(d.original),
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: (d) => this.delete(d),
    },
  ];
  delete = (value) => {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => this.buttonDelete(value.original)}
      >
        Delete
      </button>
    );
  };
  buttonDelete = async (d) => {
    if (d && d.id) {

      // if (d && d.status == "show") {
      //   swal("If You Want To Delete This Page?", "Please Change a Status To Hide", {
      //     icon: "warning",
      //   });
      // } else {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this page",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            let result = await cmsContent.deleteMaster("tbl_pages", d.id)
            if (result) {
              await this.MainFunc()
              swal("Your page has been deleted!", {
                icon: "success",
              });
            } else {
              await this.MainFunc()
              swal("Something went wrong. Try Again", {
                icon: "warning",
              });
            }
          }
        });
    }
    // }
  }
  showTheme = (d) => {
    if (d && d.original && d.original.themeId) {
      if (d.original.themeId == 1) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 2) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 3) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 4) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 5) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 6) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 7) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 8) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 9) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 11) {
        return `Theme-${d.original.themeId}`;
      }
      if (d.original.themeId == 12) {
        return `Theme-${d.original.themeId}`;
      }
    }
  };

  homepage = (d) => {
    //console.log("home", d);
    let org = d;

    let colour = org.homepage == 1 ? "btn btn-success" : "btn btn-warning";
    let text = org.homepage == 1 ? "Home" : "SetusHome";
    if (org.homepage == 1) {
      return (
        <button
          type="button"
          className={colour}
          style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '15px' }}
          onClick={() => this.sethome(d)}
        >
          {text}
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className={colour}
          style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '15px' }}
          onClick={() => this.sethome(d)}
        >
          {text}
        </button>
      );
    }
  };
  sethome = async (d) => {
    const formData = new FormData();
    formData.append("id", d.id);
    formData.append("homepage", 1);
    formData.append("customerId", d.customerId);
    const inshome = await cmsContent.inserthome("tbl_pages", formData);
    //console.log(inshome);
    this.setState({ data: inshome.data });
    // let index = this.state.data.indexOf(d);
    // console.log("in", index);
    // let send = inshome.data[0];
    // const previousData = [...this.state.data];
    // previousData[index] = send;
    // this.setState({ data: previousData });
  };

  PreviewPage = (d) => {
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.PreviewRoute(d)}
      >
        Preview
      </button>
    );
  };

  PreviewRoute = (d) => {
    console.log(d.original);
    let id = d.original.id;
    let themeid = d.original.themeId;
    let customerId = d.original.customerId;
    if (themeid === 7) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/th7/${id}/${customerId}`,
        "_blank"
      );
    } else if (themeid === 1) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/commonpg/${id}/${customerId}`,
        "_blank"
      );
    } else if (themeid === 3) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/dynamicth3/${id}/${customerId}`,
        "_blank"
      );
    } else if (themeid === 4) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/dynamicth4/${id}/${customerId}`,
        "_blank"
      );
    } else if (themeid === 5) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/th5/${id}/${customerId}`,
        "_blank"
      );
    } else if (themeid === 6) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/th6/${id}/${customerId}`,
        "_blank"
      );
    } else if (themeid === 9) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/th9/${id}/${customerId}`,
        "_blank"
      );
    } else if (themeid === 8) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/th8/${id}/${customerId}`,
        "_blank"
      );
    }
  };

  showVisibility = (id) => {
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.updateVisibility(id)}
      >
        Hide/Show
      </button>
    );
  };
  updateVisibility = async (value) => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };

    if (newData.status === "show") {
      newData.status = "hide";
    } else {
      newData.status = "show";
    }
    const id = newData.id;
    const data = previousData.filter((value) => value.id !== id);
    data.splice(index, 0, newData);
    try {
      const result = await cmsContent.updatePage(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };
  changeLoginStatus = (id) => {
    return (
      <button
        type="button"
        class="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
        onClick={() => this.updateLogin(id)}
      >
        Change Login Status
      </button>
    );
  };

  updateLogin = async (value) => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };
    if (newData.requireLogin === "yes") {
      newData.requireLogin = "no";
    } else {
      newData.requireLogin = "yes";
    }
    const id = newData.id;
    const data = previousData.filter((value) => value.id !== id);
    data.splice(index, 0, newData);
    this.setState({ data });
    try {
      const result = await cmsContent.updateLoginStatus(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };
  Editpages = (value) => {
    return (
      <button className="btn btn-info" onClick={() => this.onEdit(value)}>
        Edit
      </button>
    );
  };
  onEdit = (value) => {
    let d = value.original;
    // console.log(d, 'dd');
    let autherSelected = [];
    let themeSelected = [];
    let appendOverlayList = JSON.parse(d.videoOverlay);
    let theme4 = d.sheetContent;
    let themeId = [];

    //Author selection
    this.state.authorlist.map((ival, k) => {
      if (ival.value == d.authorid) {
        autherSelected.push(ival);
      }
    });
    //Theme selection
    options.map((ival, k) => {
      if (ival.value == d.themeId) {
        themeSelected.push(ival);
        themeId.push(ival.value);
      }
    });
    //Theme 8 email check
    if (d.subTitleId == "true") {
      this.setState({ emailchecked: true });
    }
    let pages = [];
    this.state.data.map((ival, i) => {
      if (ival.themeId == d.themeId) {
        pages.push(ival);
      }
    });
    this.setState({
      pageName: d.name,
      autherSelected,
      themeSelected,
      themeId,
      pagenameDisable: false,
      contentTitle1: d.contentTitle1,
      content1: d.content1,
      searchTags: d.searchTags,
      fileName1: d.file,
      fileText: d.filename,
      thumbnailname: d.file2,
      isEdit: true,
      appendOverlayList,
      sheetContent: d.sheetContent,
      id: d.id,
      linkto: d.linkto,
      subTitleId: d.subTitleId,
      theme4,
      data1: d,
      datatable: this.state.data,
      // contentTitle1: d.contentTitle1,
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  async componentDidMount() {
    try {
      await this.MainFunc()
    } catch (error) {
      console.log(error);
    }
  }
  MainFunc = async () => {
    try {
      let userlog = JSON.parse(localStorage.getItem('userlog'));
      const userlog1 = await cmsContent.getFreedom(
        "tbl_user_web.*",
        "tbl_user_web",
        `id=${userlog.id}`,
        "id",
        "id DESC"
      );
      if (userlog1) {
        this.setState({ userlog2: userlog1.data[0] });
      }
      const Author = await cmsContent.getFreedom(
        "id as value,name as label",
        "tbl_author",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (Author) {
        // console.log(ACCESS_POINT.ACCESS_SAMPLE);
        this.setState({ authorlist: Author.data });
      }
      // const condition = this.state.customerId == 807 ? `customerId='${this.state.customerId}' and file_extension in ('jpg','png','jpeg') and storedAt='ftp'` :
      // `customerId='${this.state.customerId}' and file_extension in ('jpg','png','jpeg')`
      const getLibraryImage = await cmsContent.getFreedom(
        "id as value,file as label,type as hint,file_extension",
        "tbl_image",
        `customerId='${this.state.customerId}' and file_extension in ('jpg','png','jpeg')`,
        "id",
        "id DESC"
      );
      if (getLibraryImage) {
        this.setState({ getlibrarydataImage: getLibraryImage.data });
      }
      const condition1 = this.state.customerId == 807 ? `customerId='${this.state.customerId}' and file_extension in ('mp4') and storedAt='ftp'` :
        `customerId='${this.state.customerId}' and file_extension in ('mp4')`
      const getLibraryVideo = await cmsContent.getFreedom(
        "id as value,file as label,type as hint,file_extension",
        "tbl_image",
        condition1,
        "id",
        "id DESC"
      );
      if (getLibraryVideo) {
        this.setState({ getlibrarydataVideo: getLibraryVideo.data });
      }
      const pages = await cmsContent.getFreedom(
        "*",
        "tbl_pages",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (pages) {
        let productThemePresent = false;
        if (pages.data && pages.data.length > 0) {
          productThemePresent = await pages.data.some((ival) => {
            return ival.themeId == 12
          })
        }
        this.setState({ data: pages.data, productThemePresent });
      }
      if (pages.data.length > 0) {
        const header = ["SI.NO", "PAGENAME", "THEME", "STATUS"];
        const excelHead = [
          { label: "PAGENAME", key: "name" },
          { label: "THEME", key: "themeId" },
          { label: "STATUS", key: "status" },
        ];
        this.setState({ excelHead, header, excel: pages.data, title: "Pages" });
      }
    } catch (error) {
      console.log(error);
    }
  }
  handlechange = async (e, name = null) => {
    if (name == 'pageName') {
      await this.setState({ pageName: e.target.value, themeDisable: false });
    } else if (name == 'autherSelected') {
      await this.setState({ autherSelected: e, pagenameDisable: false })
    } else {
      await this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleTheme = (selectedOption) => {
    let { userlog2, productThemePresent } = this.state;
    if (selectedOption.value == '12') {
      if (userlog2.templateCheck == 1 && productThemePresent === true) {
        alert("Product theme (Theme-12) is already present in your pages list, kindly use that :)");
      } else {
        this.setState({
          themeSelected: selectedOption,
          themeId: selectedOption.value,
          themeName: selectedOption.label,
        });
      }
    } else {
      this.setState({
        themeSelected: selectedOption,
        themeId: selectedOption.value,
        themeName: selectedOption.label,
      });
    }


  };
  CheckBox = async (d) => {
    if (d.target.checked) {
      await this.setState({ checked: 1 });
    } else {
      await this.setState({ checked: 0 });
    }
  };
  pagenameError = async (s, v) => {
    this.setState({ [s]: v });
    console.log("v", v);
    if (this.state.alertVisible === true) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    }
  };

  onDismiss = () => {
    //console.log(1);
    this.setState({ alertVisible: false, formAlert: false });
    this.setState({ alertVisible: false, formAlertdelete: false });
  };

  handleImage = (e) => {
    let filename = e.target.files[0];
    let filepath = e.target.files[0].name;

    this.setState({ file1: filename });
    this.setState({ authorlogo: filepath });
  };

  addNewauthor = async () => {
    const {
      authorname,
      authoremail,
      authorlogo,
      authorabout,
      file1,
    } = this.state;
    if (!authorname) {
      this.setState({ errorauthorname: "enter author name " });
      return false;
    } else if (!authoremail) {
      this.setState({
        errorauthoremail: "enter email_id",
        errorauthorname: "",
      });
      return false;
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!authoremail.match(mailformat)) {
        this.setState({ errorauthoremail: "Please Enter correct mail" });
      } else {
        this.setState({ errorauthoremail: "" });
      }
    }
    const formData = new FormData();
    formData.append("name", authorname.trim());
    formData.append("email", authoremail.trim());
    formData.append("about", authorabout.trim());
    formData.append("image", file1);
    formData.append("status", "active");
    formData.append("customerId", this.state.customerId);
    // console.log([...formData]);

    try {
      const authorInsert = await cmsContent.authorinsert(
        formData,
        "tbl_author"
      );
      console.log(authorInsert, 'authorInsert')
      if (authorInsert) {
        let categoryArray = {};
        categoryArray.value = authorInsert.data.insertId;
        categoryArray.label = authorname;
        const previousData = [categoryArray, ...this.state.authorlist];
        //console.log(categoryArray);
        //console.log(previousData);
        this.setState({
          authorname: "",
          authoremail: "",
          authorabout: "",
          authorlogo: "",
          file1: "",
          errorauthorabout: "",
          errorauthoremail: "",
          errorauthorname: "",
          btnDisable: false,
          alertVisible1: true,
          authorlist: previousData,
        });
        setTimeout(() => this.setState({ alertVisible1: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async componentWillReceiveProps() {
    this.setState({ themeId: 0 });

  }
  newTempWindow = (id) => {
    window.open(`${SITEBUILDER_ACCESS_POINT}/project/template/choose/${id}`)
  }

  themeOptions = () => {
    let { userlog2, pageName } = this.state;
    let themeOptions = [];
    if (userlog2.templateCheck == 0 && userlog2.templateId == null && userlog2.domain == null) {
      themeOptions = options
    } else if (userlog2.templateCheck == 1 && userlog2.domain != null && pageName && pageName.trim().toLowerCase() === "footer") {
      themeOptions = [
        { label: "Theme-1 (To Add image and content for banner OR Footer)", value: "1" }
      ];
    } else if (userlog2.templateCheck == 1 && userlog2.domain != null) {
      themeOptions = [
        { label: "Theme-1 (To Add Content for Page)", value: "1" },
        { label: "Theme-3 (To Add images,Text and Videos)", value: "3" },
        { label: "Theme-4 (To Add Content Section wise)", value: "4" },
        { label: "Theme-5 (To Display Box type Content in Page)", value: "5" },
        { label: "Theme-6 (To Add Links in Pages)", value: "6" },
        { label: "Theme-7 (Advanced HTML Editor)", value: "7" },
        { label: "Theme-8 (To Add Form)", value: "8" },
        { label: "Theme-9 (To Add Images,Description in Page)", value: "9" },
        { label: "Theme-11 (To add a content repeatedly)", value: "11" },
        { label: "Theme-12 (To add a product repeatedly)", value: "12" }
      ]
    }
    return themeOptions;
  }

  render() {
    let { userlog2, footerHintShow } = this.state

    if (userlog2.templateId == null && userlog2.templateCheck == 1 && userlog2.domain != null) {
      return (
        <React.Fragment>
          <main className="main my-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 style={{ textAlign: 'center' }}>Please choose a template using below link before adding a page<span style={{ fontSize: '30px' }}>↓</span></h3>
                    </div>
                    <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-7">
                          <center>
                            <button className="submitButton" onClick={() => this.newTempWindow(userlog2.id)}>
                              Choose a template
                              <svg className="subIcon" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </center>

                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <br></br>
                      <center style={{ display: "flex", justifyContent: "center" }}>
                        <p style={{ paddingTop: '6px', fontSize: "large", fontWeight: "600", color: "#35393c" }}>  If you have already selected a template, then click on </p> &nbsp; &nbsp;
                        <button className="btn btn-primary" onClick={() => { window.location.reload() }}>Refresh</button>
                        &nbsp; &nbsp; <p style={{ paddingTop: '6px', fontSize: "large", fontWeight: "600", color: "#35393c" }}> to refresh this page!</p>
                      </center>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </main>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <main className="main my-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3>Add Page</h3>
                    </div>
                    <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-7">
                          <Alert
                            className="badge-content"
                            color="success"
                            isOpen={this.state.alertVisible}
                            toggle={this.onDismiss}
                          >
                            Page Added Successfully
                          </Alert>
                        </div>
                        <div className="col-sm-3" />
                      </div>

                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label htmlFor="authorName">Author Name</label>
                        </div>
                        <div className="col-sm-5">
                          <SingleSelect
                            name="authorName"
                            options={this.state.authorlist}
                            handleChange={(d) => this.handlechange(d, 'autherSelected')}
                            selectedService={this.state.autherSelected}
                          />
                        </div>
                        <div className="col-sm-3">
                          <LoginModal
                            buttonTitle="Add Author"
                            title="Add Author"
                            id="author"
                            extraClass="btn-width"
                            onClick={this.reset}
                            bodyText={
                              <Addauthormodel
                                authorname={this.state.authorname}
                                handleChange={(d) => this.handlechange(d)}
                                addNewauthor={this.addNewauthor}
                                errorauthorname={this.state.errorauthorname}
                                authoremail={this.state.authoremail}
                                errorauthoremail={this.state.errorauthoremail}
                                authorabout={this.state.authorabout}
                                errorauthorabout={this.state.errorauthorabout}
                                authorlogo={this.state.authorlogo}
                                handleImage={this.handleImage}
                                alert={this.state.alertVisible1}
                                dismiss={this.onDismiss}
                                disableValue={this.state.btnDisable}
                                alertMsg="New author Added"
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-4" />
                        <span className="error-show ">
                          {this.state.errorAuthorName}
                        </span>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label htmlFor="exampleInputEmail1">Page Name</label>
                        </div>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            id="pageName"
                            name="pageName"
                            placeholder="Enter Page Name"
                            value={this.state.pageName}
                            onChange={(d) => this.handlechange(d, 'pageName')}
                            disabled={this.state.pagenameDisable}
                            onFocus={() => this.setState({ footerHintShow: true })}
                            onBlur={() => this.setState({ footerHintShow: false })}
                          />
                        </div>
                        <div className="col-sm-3">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="ShowChecked"
                              onClick={this.CheckBox}
                              checked={this.state.checked}
                            />
                            <label class="form-check-label" for="materialChecked" style={{ marginLeft: '10px', marginTop: '9px' }}>
                              Show
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-4" />
                        <span className="error-show ">
                          {this.state.errorPageName}
                        </span>
                      </div>
                      {
                        (userlog2.templateCheck == 1 && footerHintShow)
                          ?
                          <center>
                            <span style={{ color: "red" }}>
                              Note:  If you want to create footer for your template, then name your Page name as "Footer"
                            </span>
                          </center>
                          :
                          <div />
                      }
                      <br />
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label htmlFor="exampleInputEmail1">Select Theme</label>
                        </div>
                        <div className="col-sm-5">
                          <SingleSelect
                            options={this.themeOptions()}
                            handleChange={this.handleTheme}
                            selectedService={this.state.themeSelected}
                            disabled={this.state.themeDisable}
                          />
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-4" />
                        <span className="error-show ">
                          {this.state.errorThemeId}
                        </span>
                      </div>
                      {this.state.themeId == "1" ? (
                        <Theme1 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "2" ? (
                        <Theme2 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "3" ? (
                        <Theme3 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "4" ? (
                        <Theme4 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "5" ? (
                        <Theme5 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "6" ? (
                        <Theme6 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "7" ? (
                        <Theme7 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "8" ? (
                        <Theme8 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "9" ? (
                        <Theme9 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "10" ? (
                        <Theme10 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "11" ? (
                        <Theme11 that={this.state} these={this.pagenameError} />
                      ) : null}
                      {this.state.themeId == "12" ? (
                        <Theme12 that={this.state} these={this.pagenameError} />
                      ) : null}
                      <br />
                      {this.state.excel && this.state.excel.length > 0 ? (
                        <PDFDownloadLink
                          style={{ color: "white" }}
                          document={
                            <AddpagePDF
                              title={this.state.title}
                              header={this.state.header}
                              data={this.state.excel}
                            />
                          }
                          fileName="pages.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download Pdf"
                          }
                          <button className="btn btn-warning">PDF</button>
                        </PDFDownloadLink>
                      ) : null}
                      {this.state.data && this.state.data.length ? (
                        <CSVLink
                          data={this.state.data}
                          headers={this.state.excelHead}
                        >
                          <button
                            className="btn btn-success"
                            style={{ marginLeft: "5px" }}
                          >
                            Excel
                          </button>
                        </CSVLink>
                      ) : null}
                      <br></br>
                      <br></br>

                      <div className="row form-group">
                        <div className="col-sm-12">
                          {this.state.data && this.state.themeId === 0 ? (
                            <Datatable
                              data={this.state.data}
                              columnHeading={this.column}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </React.Fragment>
      );
    }
  }
}

export default AddPagebc;
